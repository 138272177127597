import { hasData } from "./hasData";

export function textProcess(n) {
  return n;
  // var varn = n?.toString();

  // const date = new Date();

  // //april fools reference
  // if (date.getMonth() != 3 || date.getDate() != 1) return varn;

  // if (!hasData(varn)) return varn;

  // varn = varn?.replace(/[a]/gi, "ᗋ");
  // varn = varn?.replace(/[s]/gi, "💲");
  // varn = varn?.replace(/[c]/gi, "🌜");
  // varn = varn?.replace(/[p]/gi, "🅿");
  // varn = varn?.replace(/[r]/gi, "®️");
  // varn = varn?.replace(/[n]/gi, "Ꞃ");
  // varn = varn?.replace(/[k]/gi, "𝓚");
  // varn = varn?.replace(/[t]/gi, "🌴");
  // return varn;
}
